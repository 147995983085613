<template>
  <ProductFeature
    :product="product"
    :title="title"
    :description="description"
    :button-text="buttonText"
    :image="image"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  export default {
    mixins: [AlchemyElement],
    computed: {
      product() {
        return this.getIngredient("product")
      },
      title() {
        return this.getValue("title")
      },
      description() {
        return this.getRichtext("description")
      },
      buttonText() {
        return this.getValue("button_text")
      },
      image() {
        return this.getValue("image")
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "~/assets/_variables";
</style>
